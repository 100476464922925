<template>
	<div class="google-auth-confirmation">

		<h1>Подключение аккаунта Google</h1>

		<v-progress-circular v-if="loading" class="mt-4" indeterminate />
		<template v-else-if="success">
			<v-alert class="mt-4"
					 dense
					 text
					 type="success">
				Спасибо!
				Теперь Вы можете экспортировать КП в сервис Google Sheets.
			</v-alert>

			<v-btn class="mt-4" color="primary" @click="$router.push({name:'orders'})">Продолжить</v-btn>
		</template>

	</div>
</template>

<script>
export default {
	name: "google-auth-confirmation",
	data() {
		return {
			success: false,
			loading: true,
		}
	},
	watch: {
		'$route.path': {
			immediate: true,
			handler() {
				console.log("ROUTE", this.$route.query);
				if ( this.$route.query?.code ) this.save(this.$route.query?.code);
			}
		}
	},
	methods: {
		save(code) {
			this.loading = true;

			this.$store.dispatch('post', {
				action: 'GoogleAuthCodeController',
				params: {
					code,
					redirectUri: window.location.protocol + "//" + window.location.host + "/settings/google/auth/confirmation"
				}
			}).then((res) => {
				this.success = true;
			}).catch((error) => {
				console.log("ERROR: ", error);
			}).finally(() => {
				this.loading = false;
			})
		},
	}
}

</script>

<style lang="scss">

</style>
